<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="() => router.push('/crawl-options')">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>


  <!-- Custom Refresher Content -->
  <ion-content>



       <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
         <ion-refresher-content>
         </ion-refresher-content>
        </ion-refresher>
      <!--ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>{{ bar.name }}</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid-->
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{ bar.districtName }}</ion-card-subtitle>
          <ion-card-title>{{ bar.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-img :src="apiUrl + bar.image" style="height: 30vh; object-fit: cover " />
        </ion-card-content>
      </ion-card>
      <ion-grid v-if="!paused && !resumed && minutesRemaining > 0">
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; margin-top: 0; text-align: center"><strong>{{ minutesRemaining }} minutes until<br />your next bar</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="paused">
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; margin-top: 0; text-align: center"><strong>We're on pause! We'll let you know when it's time to go.</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="(!paused && minutesRemaining === 0) || resumed">
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="navigateToNextStepInCrawl()">Continue -&gt;</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="paused || minutesRemaining > 0">
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="startChallenge()">Challenge my friends</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/crawl-arrived')">Another drink suggestion</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  alertController,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';
import { warning, ellipsisHorizontal, chevronDownCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js';
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'CrawlAtBar',
  components: {
    //IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    IonRefresher,
    IonRefresherContent,
  },
  setup() {
    const { Storage } = Plugins;

    return {  warning,
              ellipsisHorizontal,
              chevronDownCircleOutline,
              console,
              router: useRouter(),
              Storage }
  },
  data: function() {
    return {
      barName: "Bellhop",
      crawl: null,
      minutesRemaining: null,
      bar: {
        "name": "",
        "image": ""
      },
      timestampLoaded: new Date,
      minutesCountdown: null,
      apiUrl: "",
      paused: false,
      resumed: false,
      latestClueId: null,
    }
  },
  created: async function() {
    console.log('Created.');
  },
  mounted: async function() {
    const self = this;
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    const self = this;
    self.apiUrl = apiMethods.apiUrl;
    apiMethods.crawlApi.visibilitychangeCallback = async function() {
      if (document.visibilityState === 'visible') {
        await self.doRefresh();
      }
    };
    apiMethods.crawlApi.Storage = self.Storage;
    self.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    self.bar = await apiMethods.crawlApi.getCurrentBar();
    self.minutesRemaining =
      self.crawl.minutesUntilNextMove;
    if(self.minutesCountdown) {
      clearInterval(self.minutesCountdown);
    }
    this.minutesCountdown = setInterval(this.decrementMinutesRemaining, 1000 * 60);
    self.latestClueId = self.crawl.current_clue_id;
    if ('paused' != self.crawl.status) {
      self.paused = false;
      self.resumed = false;
    } else {
      self.paused = true;
      self.resumed = false;
    }
  },
  computed: {
  },
  methods: {
     doRefresh: async function (event) {
       const self = this;
      console.log('Begin async operation');
       self.apiUrl = apiMethods.apiUrl;
       apiMethods.crawlApi.Storage = self.Storage;
      self.crawl = await apiMethods.crawlApi.getCurrentCrawl();
      self.bar = await apiMethods.crawlApi.getCurrentBar();
    self.minutesRemaining =
      self.crawl.minutesUntilNextMove;
    if(self.minutesCountdown) {
      clearInterval(self.minutesCountdown);
    }
    this.minutesCountdown = setInterval(this.decrementMinutesRemaining, 1000 * 60);
      self.latestClueId = self.crawl.current_clue_id;
      if ('paused' != self.crawl.status) {
        self.paused = false;
        self.resumed = false;
      } else {
        self.paused = true;
        self.resumed = false;
      }

      if(event) {
        event.target.complete();
      }
      /*setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);*/
    },
    getDrinkSuggestion: async function(duration) {
      const self = this;
      const drinkData = await apiMethods.crawlApi.getDrinkSuggestion();
      self.drinkImage = drinkData.drinkImage;
      self.drink = drinkData.drink;
      self.drinkSuggested = true;
    },
    decrementMinutesRemaining: async function() {
      const self = this;
      self.crawl = await apiMethods.crawlApi.getCurrentCrawl();
      /*if ('paused' == self.crawl.status && self.minutesCountdown) {
        clearInterval(self.minutesCountdown);
      }*/

      if ('paused' != self.crawl.status && self.paused == true) {
        self.resumed = true;
      }

      if ('paused' != self.crawl.status) {
        self.paused = false;
      } else {
        self.paused = true;
      }

      console.log('Decrementing minutes:');
      if(this.minutesRemaining > 0) {
        this.minutesRemaining--;
      }
    },
    startChallenge: async function() {
      const self = this;
      const challengeData = await apiMethods.crawlApi.startChallenge();
      console.log('Starting challenge:');
      await this.Storage.set({
        key: 'newChallenge',
        value: JSON.stringify({
          id: challengeData.id
        })
      });
      this.router.push('/crawl-trivia');
    },
    navigateToNextStepInCrawl: async function() {
      const self = this;
      console.log('Navigate to next step in crawl.');

      // Refresh the crawl
      self.crawl = await apiMethods.crawlApi.getCurrentCrawl();

      if('finished' == self.crawl.status){
        self.router.push('/crawl-finished');
      } else if(self.latestClueId == self.crawl.current_clue_id) {
        self.presentAlert();
      } else {
        self.router.push('/crawl-clue');
      }
    },
    presentAlert: async function() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Hang tight.',
          subHeader: '🙂',
          message: 'We\'re lining up your next steps. Please try again in just a minute.',
          buttons: ['OK'],
        });
      return alert.present();
    },
  }
});
</script>

<style scoped>

</style>