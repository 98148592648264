<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-slides v-if="districts.length" pager="true" :options="slideOpts">
  <ion-slide v-for="district in districts" :key="district.id">
    <ion-card :disabled="district.status != 1">
      <ion-card-header>
        <ion-card-subtitle>{{ district.city || 'Near You'}}, {{ district.state || 'Let\'s Go!' }}</ion-card-subtitle>
        <ion-card-title>{{ district.name }}</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <img v-if="district.image" :src="apiUrl + 'storage/' + district.image" />
      </ion-card-content>
    </ion-card>
  </ion-slide>
</ion-slides>
  <ion-grid>
    <ion-row v-show="hasRecentlyCreatedCrawl">
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/new-crawl-ready')"  color="success">Start Crawl</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/new')">New Crawl</ion-button>
      </ion-col>
    </ion-row>
    <ion-row v-show="!hasRecentlyCreatedCrawl && crawl && 'finished' != crawl.status">
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/crawl-at-bar')">Crawl in Progress</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/join-crawl')">Join a crawl</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon
} from '@ionic/vue';
import { add, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
//import { WonderPush } from '@ionic-native/wonderpush/ngx';
import * as apiMethods from '../app/api.js';
import { Plugins } from '@capacitor/core';
//import { HTTP } from '@ionic-native/http/ngx';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
  },
  setup() {
    //const http = new HTTP;
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: "auto",
      centeredSlides: true,
      freeMode: false,
      pagination: false
    };
    return {
      router: useRouter(),
      add,
      //http,
      console,
      slideOpts,
      warning,
      Storage
    }
  },
  async created() {
    try {
      const response = await fetch(apiMethods.crawlApi.url + 'api/districts');
      const data = await response.json();
      this.districts = Array.isArray(data.districts) ? data.districts : [];
      console.log('Districts loaded:', this.districts);
    } catch (error) {
      console.error('Error fetching districts:', error);
      this.districts = []; // Ensure districts is always an array
    }
  },
  data: function() {
    return {
      hasRecentlyCreatedCrawl: false,
      crawl: {},
      districts: [],
      apiUrl: apiMethods.crawlApi.url
    };
  },
  mounted: async function() {
    //apiMethods.crawlApi.wonderPush = new WonderPush;
    //const wonderPush = new apiMethods.WonderPush;
    if(!apiMethods.crawlApi.initialized){
      apiMethods.crawlApi.init();
      apiMethods.crawlApi.wonderPush.subscribeToNotifications();

      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      console.log(uniqueId);
    }
    //if(apiMethods.crawlApi.code) {
      await apiMethods.crawlApi.getCurrentCrawl();
    //}
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.hasRecentlyCreatedCrawl = await apiMethods.crawlApi.hasRecentlyCreatedCrawl();
  },
  methods: {
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

.swiper-slide{
  width: calc(100% - 15vw);
}

.scroll{
    height: 100%;
}
</style>