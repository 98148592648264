import { WonderPush } from '@ionic-native/wonderpush/ngx';
import Echo from 'laravel-echo';
import router from '../router';

export const apiUrl = process.env.VUE_APP_API_URL || 'https://tapcrawl.com/';


export const poll = async ({ fn, validate, interval, maxAttempts }) => {
    let attempts = 0;

    const executePoll = async (resolve, reject) => {
        const result = await fn();
        attempts++;

        if (validate(result)) {
            return resolve(result);
        } else if (maxAttempts && attempts === maxAttempts) {
            return reject(new Error('Exceeded max attempts'));
        } else {
            setTimeout(executePoll, interval, resolve, reject);
        }
    };

    return new Promise(executePoll);
};

export const crawlApi = {
    Storage: null,
    Device: null,
    wonderPush: null,
    Pusher: null,
    pusher: null,
    Echo: null,
    crawl: null,
    drink: null,
    drinkImage: null,
    code: null,
    url: apiUrl,
    bar: null,
    creatingGame: false,
    name: null,
    player: null,
    uniqueId: null,
    triviaResult: null,
    initialized: false,
    usingPush: false,
    visibilitychangeCallback: null,

    init: async function() {
        const self = this;
        if(this.initialized) {
            return;
        }
        console.log('init');
        this.wonderPush = new WonderPush;

        /*
        const channel = Echo.channel('my-channel');
        channel.listen('.my-event', function(data) {
        alert(JSON.stringify(data));
        });*/

        let pushToken = null;
        try {
            pushToken = await this.wonderPush.getPushToken();
            this.usingPush = true;
        } catch (e) {
            console.log("no push token found", e);
            this.usingPush = false;
            this.Pusher = require('pusher-js');

            this.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'b18e83c9925939aa4972',
                cluster: 'us2',
                forceTLS: false
            });
        }


        document.addEventListener("visibilitychange", async function() {
            if (typeof self.visibilitychangeCallback === 'function') {
                self.visibilitychangeCallback();
            } else {
                if (document.visibilityState === 'visible') {
                    //alert('visible')
                    console.log('Visible.');
                    await self.fetchCrawl();
                } else {
                    ////alert('not visible')
                }
            }
        });

        this.initialized = true;
    },
    setNewBarDurationAndCreateCrawl: async function(duration) {
      const self = this;
      await self.Storage.set({
        key: 'newCrawlBarDuration',
        value: JSON.stringify({
          num: duration
        })
      });
      self.creatingGame = true;
      await self.createCrawl();
    },
    createCrawl: async function() {
      const self = this;
      const ret1 = await self.Storage.get({ key: 'newCrawlLocation' });
      const location = JSON.parse(ret1.value);
      const ret2 = await self.Storage.get({ key: 'newCrawlNumStops' });
      const stops = JSON.parse(ret2.value);
      const ret3 = await self.Storage.get({ key: 'newCrawlBarDuration' });
      const duration = JSON.parse(ret3.value);
      const ret4 = await self.Storage.get({ key: 'name' });
      const name = ret4.value;

      const deviceInfo = await self.Device.getInfo();

      const uniqueId = await self.getUniqueId();

      //const wonderPush = new self.WonderPush;

      let pushToken = null;
      try {
        pushToken = await this.wonderPush.getPushToken();
        self.usingPush = true;
      } catch (e) {
        console.log("no push token found", e);
        self.usingPush = false;
      }

      const response = await fetch(self.url + 'api/games', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "district_id": location.id,
          "bar_duration": duration.num,
          "length": stops.num,
          "device_id": deviceInfo.uuid,
          "device_platform": deviceInfo.platform,
          "unique_id": uniqueId,
          "push": pushToken,
          "name": name,
        }),
      });

      const data = await response.json();

      self.creatingGame = false;
      console.log(data);
      //self.Storage = self.Storage;
      self.setCode(data.code);
      self.setCrawl(data);
      self.setUniqueId(data.player_unique_id);
      self.setPlayer({
          "unique_id": data.player_unique_id,
          "name": data.player_name,
          "contact_value": data.player_contact_value,
          "contact_method": data.player_contact_method
      });
      //self.router.push('/new-crawl-ready');

    },

    getCurrentCrawl: async function() {
        //const ret = await this.Storage.get({ key: 'crawl' });
        //this.crawl = JSON.parse(ret.value);
        //if (!this.crawl) {
            await this.fetchCrawl();
        //}
        return this.crawl;
    },
    getUniqueId: async function() {
        const self = this;
        const ret = await self.Storage.get({ key: 'unique_id' });
        if(ret) {
          return ret.value;
        }
        return null;

    },
    fetchCrawl: async function () {
        const ret = await this.Storage.get({ key: 'crawl' });
        const crawl = JSON.parse(ret.value);

        const code = await this.Storage.get({ key: 'code' });
        this.code = JSON.parse(code.value);

        if(null === this.code) {
            return JSON.parse('{"status": "Not Found"}');
        }

        const response = await fetch(this.url + 'api/games/by-code/' + this.code, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const crawlData = await response.json();

        if (!this.usingPush) {
            this.setEchoRoutes();
        }
        return await this.setCrawl(crawlData);
    },
    setCrawl: async function(crawl) {
        await this.Storage.set({
            key: 'crawl',
            value: JSON.stringify(crawl)
        });
        this.crawl = crawl;

        return crawl;
      //this.router.push('/home');
    },
    setDrink: async function (drink) {
        await this.Storage.set({
            key: 'drink',
            value: drink,
        });
        this.drink = drink;
        return drink;
    },
    setCode: async function (code) {
        console.log('setting code');
        await this.Storage.set({
            key: 'code',
            value: JSON.stringify(code)
        });
        this.code = code;
        console.log('set code.');
        return code;
        //this.router.push('/home');
    },
    setName: async function (name) {
        await this.Storage.set({
            key: 'name',
            value: name,
        });
        this.name = name;
    },
    setUniqueId: async function (uniqueId) {
        await this.Storage.set({
            key: 'unique_id',
            value: uniqueId,
        });
        this.uniqueId = uniqueId;
    },
    setTriviaResult: async function (triviaResult) {
        await this.Storage.set({
            key: 'trivia_result',
            value: triviaResult,
        });
        this.triviaResult = triviaResult;
    },
    setPlayer: async function (player) {
        console.log('setting player');
        await this.Storage.set({
            key: 'player',
            value: JSON.stringify({
                "unique_id": player.unique_id,
                "name": player.name,
                "contact_value": player.contact_value,
                "contact_method": player.contact_method
            })
        });
        this.player = player;
        console.log('set player.');
        return player;
        //this.router.push('/home');
    },
    getDrinkSuggestion: async function (barId) {
        const self = this;
        console.log('Getting drink suggestion for crawl: ');
        console.log(this.crawl);

        const response = await fetch(this.url + 'api/drinks/' + barId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const drinkData = await response.json();

        self.setDrink(drinkData.drink);
        self.drinkImage = drinkData.drinkImage;
        self.drinkSuggested = true;
        return drinkData;
        // this.router.push('/join-success');
    },
    getLatestClue: async function () {
        const self = this;
        //if(null == this.crawl) {
            this.crawl = await self.getCurrentCrawl();
        //}
        console.log('Getting latest clue for crawl: ');
        console.log(this.crawl);

        if (null === this.crawl || null === this.crawl.code) {
            return JSON.parse('{"status": "Not Found"}');
        }

        const response = await fetch(this.url + 'api/games/latest-clue/' + this.crawl.code, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const clueData = await response.json();

        //self.setDrink(drinkData.drink);
        //self.drinkImage = drinkData.drinkImage;
        //self.drinkSuggested = true;
        return clueData;
        // this.router.push('/join-success');
    },
    getCurrentBar: async function () {
        const self = this;
        //if (null == this.crawl) {
            this.crawl = await self.getCurrentCrawl();
        //}
        console.log('Getting latest bar for crawl: ');
        console.log(this.crawl);

        const response = await fetch(this.url + 'api/games/latest-bar/' + this.crawl.code, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const barData = await response.json();

        //self.setDrink(drinkData.drink);
        //self.drinkImage = drinkData.drinkImage;
        //self.drinkSuggested = true;
        return barData;
        // this.router.push('/join-success');
    },
    getCurrentChallenge: async function() {
        const self = this;
        //this.crawl = await self.getCurrentCrawl();
        const response = await fetch(this.url + 'api/games/current-challenge/' + this.crawl.code, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const challengeData = await response.json();
        return challengeData;
    },
    startChallenge: async function() {
        const self = this;
        const uniqueId = await self.getUniqueId();
        const response = await fetch(this.url + 'api/games/start-challenge/' + this.crawl.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "unique_id": uniqueId,
            }),
        });
        const challengeData = await response.json();
        return challengeData;
    },
    completeStaticChallenge: async function() {
        const self = this;
        const uniqueId = await self.getUniqueId();
        const response = await fetch(this.url + 'api/games/end-challenge/' + this.crawl.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "unique_id": uniqueId,
            }),
        });
        const crawlData = await response.json();
        return crawlData;
    },
    answerTriviaQuestion: async function(answer) {
        const self = this;
        const uniqueId = await self.getUniqueId();
        const deviceInfo = await self.Device.getInfo();
        const response = await fetch(this.url + 'api/games/answer-trivia-question/' + this.crawl.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "answer": answer,
                //"player": null,
                //"bar_duration": duration.num,
                //"length": stops.num,
                "unique_id": uniqueId,
                "device_id": deviceInfo.uuid,
            }),
        });
        const triviaAnswered = await response.json();

        //self.setDrink(drinkData.drink);
        //self.drinkImage = drinkData.drinkImage;
        //self.drinkSuggested = true;
        return triviaAnswered;
    },
    finishAcceptingResponses: async function(){
        const self = this;
        const uniqueId = await self.getUniqueId();
        const deviceInfo = await self.Device.getInfo();
        const response = await fetch(this.url + 'api/games/cancel-challenge/' + this.crawl.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "answer": '',
                //"player": null,
                //"bar_duration": duration.num,
                //"length": stops.num,
                "unique_id": uniqueId,
                "device_id": deviceInfo.uuid,
            }),
        });
        const triviaAnswered = await response.json();

        //self.setDrink(drinkData.drink);
        //self.drinkImage = drinkData.drinkImage;
        //self.drinkSuggested = true;
        console.log('finished accepting responses');
        return triviaAnswered;
    },
    setEchoRoutes: function() {
        console.log('Setting Echo routes.');
        this.Echo.channel('games.' + this.code)
            .listen('.event', (e) => {
                console.log(e.route);
                router.push({
                    path: e.route
                });
            });
    },
    isLeaderForCurrentGame: async function() {
        console.log('Determining leader status.');

        const ret = await this.Storage.get({ key: 'crawl' });
        let crawl = JSON.parse(ret.value);
        if (!crawl) {
            crawl = await this.getCurrentCrawl();
        }
        if (!crawl) {
            return false;
        }
        const uniqueId = await this.getUniqueId();
        const leaderUniqueId = crawl.leader_unique_id;
        return uniqueId == leaderUniqueId;
    },
    hasRecentlyCreatedCrawl: async function() {
        const self = this;
        console.log('Determining if user has created a new crawl.');

        const ret = await this.Storage.get({ key: 'crawl' });
        let crawl = JSON.parse(ret.value);
        if (!crawl) {
            crawl = await this.getCurrentCrawl();
        }
        if (!crawl) {
            return false;
        }
        if (null === self.code) {
            return false;
        }

        return 'paused' == crawl.status
            && Object.keys(crawl.clues_used).length === 0
            && self.isLeaderForCurrentGame();
    },
    listPlayers: async function () {
        const self = this;
        const uniqueId = await self.getUniqueId();
        const deviceInfo = await self.Device.getInfo();
        const response = await fetch(this.url + 'api/games/players/' + this.crawl.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                //"player": null,
                //"bar_duration": duration.num,
                //"length": stops.num,
                "unique_id": uniqueId,
                "device_id": deviceInfo.uuid,
            }),
        });
        const playersList = await response.json();

        //self.setDrink(drinkData.drink);
        //self.drinkImage = drinkData.drinkImage;
        //self.drinkSuggested = true;
        console.log('got list of ' + playersList.players.length + ' player(s)');
        console.log(playersList);
        console.log(deviceInfo);
        return playersList.players;
    },
    setHost: async function (playerUniqueId) {
        const self = this;
        const uniqueId = await self.getUniqueId();
        const deviceInfo = await self.Device.getInfo();
        const response = await fetch(this.url + 'api/games/set-host/' + this.crawl.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                //"player": null,
                //"bar_duration": duration.num,
                //"length": stops.num,
                "unique_id": uniqueId,
                "device_id": deviceInfo.uuid,
                "host_id": playerUniqueId,
            }),
        });
        const crawlData = await response.json();

        //self.setDrink(drinkData.drink);
        //self.drinkImage = drinkData.drinkImage;
        //self.drinkSuggested = true;
        if (false === crawlData.result) {
            console.log('setting host unsuccessful');
            return false;
        } else {
            console.log('successfully set host');
            console.log(crawlData);
            return true;
        }
    },
};