<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="() => router.push('/crawl-options')">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>{{ bar.name }}</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h3 style="text-align: center">The answer is:</h3>
            </ion-text>
            <ion-text v-if="crawl.previous_challenge_answer" color="secondary">
              <h3 style="text-align: center"><strong><em>{{ crawl.previous_challenge_answer }}</em></strong></h3>
            </ion-text>
            <ion-text v-else color="secondary">
              <h3 style="text-align: center"><strong><em>{{ crawl.active_challenge_answer }}</em></strong></h3>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row v-show="crawl.active_challenge_winner_id">
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; text-align: center"><strong>{{  crawl.active_challenge_winner_name }} got the correct answer first!</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row v-show="!crawl.active_challenge_winner_name">
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; text-align: center"><strong>No one got the correct answer!</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/crawl-at-bar')">Back to the crawl -&gt;</ion-button>
          </ion-col>
        </ion-row>
        <!--ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/crawl-arrived')">Another drink idea</ion-button>
          </ion-col>
        </ion-row-->
      </ion-grid>
      <ion-grid v-show="answered">
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; text-align: center"><strong>Waiting for others...</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning, ellipsisHorizontal } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'CrawlTriviaAnswer',
  components: {
    //IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              ellipsisHorizontal,
              console,
              router: useRouter(),
              Storage }
  },
  data: function() {
    return {
      bar: {
        "name": ""
      },
      challenge: {
        "question": "",
        "challenge": "",
        "type": "",
        "trivia_question": {
          "question": "",
          "answers": [{"answer": ""}]
        }
      },
      crawl: {
        "active_challenge_winner_id": "",
        "previous_challenge_id": ""
      },
      answer: "",
      answered: false
    }
  },
  created: function() {
    console.log('Created.');
    //this.getCurrentCrawl();
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.bar = await apiMethods.crawlApi.getCurrentBar();
    this.challenge = await apiMethods.crawlApi.getCurrentChallenge();
    this.answer = "";
    this.answered = false;
    console.log(this.challenge);
  },
  computed: {
      minutesRemaining: function() {
          return 16;
      }
  },
  methods: {
    answerTriviaQuestion: async function() {
      const self = this;
      self.answered = true;
      setTimeout(() => {
        self.answer = "";
        self.answered = false;
        self.router.push('/home');
      }, 2000);
    },
    getDrinkSuggestion: async function(duration) {
      const self = this;
      const drinkData = await apiMethods.crawlApi.getDrinkSuggestion();
      self.drinkImage = drinkData.drinkImage;
      self.drink = drinkData.drink;
      self.drinkSuggested = true;
    },
    getCurrentCrawl: async function() {
      const ret = await this.Storage.get({ key: 'crawl' });
      this.crawl = JSON.parse(ret.value);
      if(!this.crawl) {
        await this.fetchCrawl();

      }
    },
    setCrawl: async function(crawl) {
      await this.Storage.set({
        key: 'crawl',
        value: JSON.stringify(crawl)
      });
      this.crawl = crawl;
      //this.router.push('/home');
    },
    fetchCrawl: async function() {
      const ret = await this.Storage.get({ key: 'crawl' });
      const crawl = JSON.parse(ret.value);

      fetch(apiMethods.apiUrl + 'api/games/by-code/' + this.code, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then(res => res.json())
      .then(data => this.setCrawl(data));
    }
  }
});
</script>

<style scoped>

</style>