<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>New Crawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center">
                <strong>Where to?</strong>
              </h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div v-if="activeDistricts.length">
        <ion-card
          v-for="district in activeDistricts"
          :key="district.id"
          @click="setNewLocation(district.id)"
        >
          <ion-card-header>
            <ion-card-subtitle
              >{{ district.city || "Near You" }},
              {{ district.state || "Let's Go!" }}</ion-card-subtitle
            >
            <ion-card-title>{{ district.name }}</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <img
              v-if="district.image"
              :src="apiUrl + 'storage/' + district.image"
            />
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  alertController,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { pin, walk, warning, wifi, wine } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'

export default defineComponent({
  name: 'NewCrawlLocation',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device;
    return {  warning,
              console,
              router: useRouter(),
              Storage,
              Device }
  },
  async created() {
    try {
      const response = await fetch(apiMethods.crawlApi.url + "api/districts");
      const data = await response.json();
      this.districts = Array.isArray(data.districts) ? data.districts : [];
      console.log("Districts loaded:", this.districts);
    } catch (error) {
      console.error("Error fetching districts:", error);
      this.districts = []; // Ensure districts is always an array
    }
  },
  data: function() {
    return {
      districts: [],
      apiUrl: apiMethods.crawlApi.url,
    };
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    const self = this;
    console.log('ionviewwillenter');
    const nameFetched = await self.getName();
    //if(!nameFetched || 'crawler' == nameFetched) {
      console.log(nameFetched);
      await this.presentAlertPrompt();
    //}
  },
  methods: {
    setNewLocation: async function(locationId) {
      await this.Storage.set({
        key: 'newCrawlLocation',
        value: JSON.stringify({
          id: locationId
        })
      });

      this.router.push('/new-num-stops');
    },
    saveName: async function(name) {
      apiMethods.crawlApi.setName(name);
    },
    getName: async function() {
      const ret = await this.Storage.get({ key: 'name' });
      return ret.value;
    },
    presentAlertPrompt: async function() {
      const self = this;
      const name = await self.getName();
      const alert = await alertController
      .create({
        cssClass: 'my-custom-class',
        header: 'Enter your name:',
        inputs: [
          {
            name: 'nameEntered',
            id: 'name-entered-id',
            value: name,
            placeholder: 'Your name',
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel')
            },
          },
          {
            text: 'Ok',
            handler: (alertData) => {
              console.log('Confirm Ok');
              self.saveName(alertData.nameEntered);
            },
          },
        ],
      });
      return alert.present();
    },
  },
  computed: {
    activeDistricts() {
      return this.districts.filter(district => district.status === 1);
    }
  },
});
</script>

<style scoped>

</style>