<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="() => router.push('/crawl-options')">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>{{ bar.name }}</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="!drinkSuggested">
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong :disabled="suggesting" @click="getDrinkSuggestion(bar.id)">What to drink?</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="text-align: center"><strong>{{ drink }}</strong></h1>
              <ion-img :src="apiMethods.apiUrl + '/img/' + drinkImage"
                v-if="drinkImage"
                :alt="drink"
                style="max-width: 75vw; width: 100%; margin: 0 auto; max-height: 30vh; height: 100%">
                </ion-img>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="waterTime && drinkSuggested">
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="text-align: center"><strong>...and it's time for a water break!</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="drinkSuggested">
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong :disabled="suggesting" @click="getDrinkSuggestion(bar.id)">Something else?</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="next()">We've got our drinks -&gt;</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning, ellipsisHorizontal } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js';
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'CrawlArrived',
  components: {
    //IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              ellipsisHorizontal,
              console,
              router: useRouter(),
              apiMethods,
              Storage }
  },
  data: function() {
    return {
      barName: "Bellhop",
      crawl: null,
      drink: "",
      drinkImage: "",
      drinkSuggested: false,
      suggesting: false,
      bar: {
        "name": ""
      }
    }
  },
  computed: {
    waterTime: function() {
      const self = this;
      if(self.crawl) {
        const clueCount = Object.keys(self.crawl.clues_used).length;
        const length = self.crawl['length'];
        return clueCount == 1 + Math.floor(length / 2);
      }
      return false;
    }
  },
  created: async function() {
    console.log('Created.');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.bar = await apiMethods.crawlApi.getCurrentBar();
  },
  methods: {
    next: function() {
      const self = this;
      self.drink = "";
      self.drinkImage = "";
      self.drinkSuggested = false;
      self.router.push('/crawl-at-bar');
    },
    getDrinkSuggestion: async function(barId) {
      const self = this;

      console.log('suggesting drink for bar ID ' + barId);
      this.suggesting = true;
      const drinkData = await apiMethods.crawlApi.getDrinkSuggestion(barId);
      this.suggesting = false;
      self.drinkImage = drinkData.drinkImage;
      self.drink = drinkData.drink;
      self.drinkSuggested = true;
    }
  }
});
</script>

<style scoped>

</style>