<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="() => router.push('/crawl-options')">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Clue</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Bar Clue:</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
       <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; margin-top: 0; text-align: center"><strong>{{ clue.body }}</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
           <ion-list>
              <ion-radio-group allow-empty-selection v-model="answer">
                <ion-list-header>
                  <ion-label>Bar:</ion-label>
                </ion-list-header>

                <ion-item v-for="(id, option) in clue.options" v-bind:key="id">
                  <ion-label>{{ option }}</ion-label>
                  <ion-radio slot="start" :value="id" :disabled="answered"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="answerClue">Answer</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
       <ion-grid>
        <ion-row>
          <ion-col size="9">
            <ion-text color="--ion-color-primary-contrast" v-show="answered && clue.barId == answer">
              <h1 class="ion-margin-vertical ion-text-center">✅ <strong>Correct!</strong></h1>
            </ion-text>
            <ion-text color="--ion-color-primary-contrast" v-show="answered && clue.barId != answer">
              <h1 class="ion-margin-vertical ion-text-center">❌ <strong>Sorry, it's...</strong></h1>
            </ion-text>
          </ion-col>
          <ion-col class="ion-text-end">
            <ion-button size="large" fill="solid" strong  @click="proceedToNextPage" v-show="answered">-&gt;</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonRadioGroup,
  IonRadio
} from '@ionic/vue';
import { warning, ellipsisHorizontal } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'CrawlClue',
  components: {
    //IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonRadioGroup,
    IonRadio,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              ellipsisHorizontal,
              console,
              router: useRouter(),
              Storage }
  },
  created: async function() {
    console.log('Created CrawlClue view.');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.clue = await apiMethods.crawlApi.getLatestClue();
  },
  data: function() {
    return {
      answer: "",
      currentBarNumAdj: 'First',
      clueText: '',
      crawl: null,
      correctAnswer: "Bellhop",
      answered: false,
      clue: {
        body: '',
        options: {
          "Rooftop" : "1",
          "Bellhop" : "2",
          "Black Sheep" : "3",
        },
        barId: null
      }
    }
  },
  methods: {
    answerClue: function() {
      if("" == this.answer) {
        return;
      }
      this.answered = true;
    },
    proceedToNextPage: function(){
      this.answered = false;
      this.answer = "";
      this.router.push('/crawl-check-in');
    },
  }
});
</script>

<style scoped>

</style>