<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="() => router.push('/crawl-options')">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>{{ bar.name }}</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; text-align: center"><strong>{{ challenge.challenge }}</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="1 !== challenge.type">
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <p style="margin-bottom: 0; text-align: center"><strong>{{ challenge.trivia_question ? challenge.trivia_question.question : '' }}</strong></p>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input
              :value="answer"
              placeholder="Enter your response:"
              type="text"
              @ionInput="answer = $event.target.value;"
              style="text-align: center;"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="answerTriviaQuestion()" :disabled="answered || !answer">Answer</ion-button>
          </ion-col>
        </ion-row>
        <!--ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/crawl-arrived')">Another drink idea</ion-button>
          </ion-col>
        </ion-row-->
      </ion-grid>
      <ion-grid v-show="answeredCorrectly">
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; text-align: center"><strong>Waiting for others...</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="2 == challenge.type && !answered && isLeader">
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="finishAcceptingResponses()" >All answers are in</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="2 !== challenge.type">
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="completeStaticChallenge()" :disabled="answered">Complete the challenge</ion-button>
          </ion-col>
        </ion-row>
        <!--ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/crawl-arrived')">Another drink idea</ion-button>
          </ion-col>
        </ion-row-->
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  alertController,
} from '@ionic/vue';
import { warning, ellipsisHorizontal } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'
import { onMounted, getCurrentInstance } from 'vue'
import { WonderPush } from '@ionic-native/wonderpush/ngx';

export default defineComponent({
  name: 'CrawlTrivia',
  components: {
    //IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device;
    //apiMethods.crawlApi.WonderPush = WonderPush;
    return {  warning,
              ellipsisHorizontal,
              console,
              router: useRouter(),
              Storage,
              Device }
  },
  data: function() {
    return {
      bar: {
        "name": ""
      },
      challenge: {
        "question": "",
        "challenge": "",
        "type": "",
        "trivia_question": {
          "question": ""
        }
      },
      crawl: {
        "length": ""
      },
      answer: "",
      answered: false,
      answeredCorrectly: false,
      code: null,
      pollingForChallengeFinished: false,
      isLeader: false,
    }
  },
  created: async function() {
    console.log('Created.');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.bar = await apiMethods.crawlApi.getCurrentBar();
    this.challenge = await apiMethods.crawlApi.getCurrentChallenge();
    this.isLeader = await apiMethods.crawlApi.isLeaderForCurrentGame();
    this.answer = "";
    this.answered = false;
    console.log(this.challenge);
  },
  computed: {
      minutesRemaining: function() {
          return 16;
      }
  },
  methods: {
    answerTriviaQuestion: async function() {
      const self = this;
      self.answered = true;
      const response = await apiMethods.crawlApi.answerTriviaQuestion(self.answer);

      // check if response is correct
      if(!response.result) {
        // if not, display error alert, clear answer, and enable button
        await self.presentAlert();
        self.answered = false;
        self.answer = '';
        if(!self.pollingForChallengeFinished) {
          self.pollingForChallengeFinished = true;
          await self.pollForChallengeFinished();
        }
      } else {
        self.pollingForChallengeFinished = false;
        self.router.push('/crawl-trivia-answer');
        //await self.pollForChallengeFinished();
      }

      console.log(response);

      /* setTimeout(() => {
        self.answer = "";
        self.answered = false;
        self.router.push('/crawl-trivia-answer');
      }, 2000); */
    },
    getDrinkSuggestion: async function(duration) {
      const self = this;
      const drinkData = await apiMethods.crawlApi.getDrinkSuggestion();
      self.drinkImage = drinkData.drinkImage;
      self.drink = drinkData.drink;
      self.drinkSuggested = true;
    },
    getCurrentCrawl: async function() {
      const ret = await this.Storage.get({ key: 'crawl' });
      this.crawl = JSON.parse(ret.value);
      if(!this.crawl) {
        await this.fetchCrawl();
      }
    },
    completeStaticChallenge: async function() {
      const self = this;
      const success = await apiMethods.crawlApi.completeStaticChallenge();
      self.router.push('/crawl-at-bar');
    },
    finishAcceptingResponses: async function() {
      const self = this;
      const success = await apiMethods.crawlApi.finishAcceptingResponses();
      //self.router.push('/crawl-trivia-answer');
    },
    presentAlert: async function() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'That\'s not it!',
          subHeader: '😕',
          message: 'Try again if there\'s still time!',
          buttons: ['OK'],
        });
      return alert.present();
    },
    pollForChallengeFinished: async function(){
      let pushToken = null;
      let pushEnabled = false;
      const wonderPush = new WonderPush;
      try {
        pushToken = await wonderPush.getPushToken();
        pushEnabled = true;
      } catch (e) {
        console.log("no push token found", e);
      }
      if(pushEnabled) {
        return;
      }
      const self = this;
      apiMethods.poll({
          fn: async function() {
              console.log('function');
              // do Ajax request to get game
              return apiMethods.crawlApi.getCurrentCrawl();
          },
          validate: function(game) {
              // see if game's active challenge winner is set
              console.log('validating: '
                + game.active_challenge_winner_id);

              if (game.active_challenge_winner_id) {
                self.pollingForChallengeFinished = false;
              }

              return !!game.active_challenge_winner_id;
          },
          interval: 5000, // milliseconds
          maxAttempts: 45
      })
      .then(function(game) {
          if (self.pollingForChallengeFinished) {
            return;
          }
          // the winner is set
          self.pollingForChallengeFinished = false;
          self.router.push('/crawl-trivia-answer');
          return;
      })
      .catch(function(err){
          console.error(err);
          self.pollingForChallengeFinished = false;
          self.router.push('/crawl-at-bar');
      });
    }
  }
});
</script>

<style scoped>

</style>