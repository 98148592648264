<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>Welcome!</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Hi {{ name }}!<br />You're all set.</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
       <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong  @click="() => router.push('/home')">Back to main screen</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'JoinSuccess',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    return {  warning,
              console,
              router: useRouter(),
              Storage }
  },
  created(){
    console.log('Created.');
    this.name = this.getName();
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
  },
  data: function() {
    return {
      code: "",
      name: ""
    }
  },
  methods: {
    getName: async function() {
      const ret = await this.Storage.get({ key: 'name' });
      this.name = ret.value;
      if(!this.name) {
        this.name = 'crawler';
      }
    },
  }
});
</script>

<style scoped>

</style>