<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/newCrawlLocation"></ion-back-button>
        </ion-buttons>
        <ion-title>New Crawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>How many bars?</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid style="display: flex; flex-direction: row; justify-content: space-around">
        <ion-row>
          <ion-col>
            <ion-button expand="block"  size="large" fill="solid" strong data-cypress="num-stops-3"  @click="setNewNumStops(3)">3</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block"  size="large" fill="solid" strong  @click="setNewNumStops(4)">4</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong  @click="setNewNumStops(5)">5</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';

export default defineComponent({
  name: 'NewCrawlNumStops',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              console,
              router: useRouter(),
              Storage}
  },
  methods: {
    setNewNumStops: async function(stops) {
      await this.Storage.set({
        key: 'newCrawlNumStops',
        value: JSON.stringify({
          num: stops
        })
      });
      this.router.push('/new-bar-duration');
    }
  }
});
</script>

<style scoped>

</style>