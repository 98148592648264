<template>
  <ion-app>
    <ion-router-outlet swipeGesture="false" />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import * as apiMethods from './app/api.js';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    return {
      router: useRouter()
    };
  },
  created: function() {
    const self = this;
    document.addEventListener('wonderpush.notificationOpen', function(event) {
      console.log('Notification opened', (event as any).notification);
      if ((event as any).notificationType === 'data') {
        self.router.push('/crawl-options');
        console.log('Silent notification', (event as any).notification);
      }
    });
  }
});
</script>