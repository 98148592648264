<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="() => router.push('/crawl-options')">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Next Bar</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{ bar.districtName }}</ion-card-subtitle>
          <ion-card-title>{{ bar.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-img :src="apiUrl + bar.image" />
        </ion-card-content>
      </ion-card>
      <ion-grid>
        <ion-row v-if="'+++++' != bar.destination">
          <ion-col>
              <ion-button expand="block" @click="window.open('https://www.google.com/maps/search/?api=1&query=' + bar.destination)">Need Walking Directions?</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" @click="checkIn()">Check In!</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  //IonText,
} from '@ionic/vue';
import { pin, walk, warning, wifi, wine, ellipsisHorizontal } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js';

export default defineComponent({
  name: 'CrawlCheckIn',
  components: {
    //IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonImg,
    // IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              ellipsisHorizontal,
              console,
              window,
              router: useRouter(),
              Storage }
  },
  data() {
      return {
          bar: {},
          barName: "Bellhop",
          district: "East Village",
          //barImage: "/assets/images/bellhop.jpg",
          destination: "41.5908657,-93.6139907",
          apiUrl: ""
      }
  },
  created: async function() {
    console.log('Created.');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.bar = await apiMethods.crawlApi.getCurrentBar();
    this.apiUrl = apiMethods.apiUrl;
  },
  methods: {
    checkIn: async function() {
      /*await this.Storage.set({
        key: 'currentBar',
        value: JSON.stringify({
          id: locationId
        })
      });*/
      this.router.push('/crawl-arrived');
    }
  }
});
</script>

<style scoped>

</style>