<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Congratulations!</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h2 style="margin-bottom: 0; text-align: center"><strong>Thank you for enjoying Tapcrawl. Need a ride home?</strong></h2>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="window.open('https://m.uber.com/ul/?client_id=22oN54ZjAMkaW1zh6wByJUyLo02jQxbF')" style="--background: black; --color: white">Uber</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong  @click="window.open('https://lyft.com/ride?id=lyft&partner=XAqY9CfBy6gX')" style="--background: #FC3768; --color: white">Lyft</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/home')">Main menu</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js';
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'CrawlFinished',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              console,
              router: useRouter(),
              Storage }
  },
  data: function() {
    return {
      barName: "Bellhop",
      crawl: null,
      window: null
    }
  },
  created: async function() {
    console.log('Created.');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    this.window = window;
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
  },
  computed: {
      minutesRemaining: function() {
          return 5;
      }
  },
  methods: {
    getDrinkSuggestion: async function(duration) {
      const self = this;
      const drinkData = await apiMethods.crawlApi.getDrinkSuggestion();
      self.drinkImage = drinkData.drinkImage;
      self.drink = drinkData.drink;
      self.drinkSuggested = true;
    }
  }
});
</script>

<style scoped>

</style>