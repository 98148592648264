import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import NewCrawlLocation from '../views/NewCrawlLocation.vue';
import NewCrawlNumStops from '../views/NewCrawlNumStops.vue';
import NewCrawlBarDuration from '../views/NewCrawlBarDuration.vue';
import CrawlInProgress from '../views/CrawlInProgress.vue';
import NewCrawlReady from '../views/NewCrawlReady.vue';
import JoinCrawl from '../views/JoinCrawl.vue';
import JoinSuccess from '../views/JoinSuccess.vue';
import CrawlClue from '../views/CrawlClue.vue';
import CrawlCheckIn from '../views/CrawlCheckIn.vue';
import CrawlArrived from '../views/CrawlArrived.vue';
import CrawlAtBar from '../views/CrawlAtBar.vue';
import CrawlTrivia from '../views/CrawlTrivia.vue';
import CrawlTriviaAnswer from '../views/CrawlTriviaAnswer.vue';
import CrawlLeaving from '../views/CrawlLeaving.vue';
import CrawlFinished from '../views/CrawlFinished.vue';
import CrawlOptions from '../views/CrawlOptions.vue';
import CrawlInvite from '../views/CrawlInvite.vue';
import CrawlChangeHost from '../views/CrawlChangeHost.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/new',
    name: 'NewCrawlLocation',
    component: NewCrawlLocation
  },
  {
    path: '/new-num-stops',
    name: 'NewCrawlNumStops',
    component: NewCrawlNumStops
  },
  {
    path: '/new-bar-duration',
    name: 'NewCrawlBarDuration',
    component: NewCrawlBarDuration
  },
  {
    path: '/crawl-in-progress',
    name: 'CrawlInProgress',
    component: CrawlInProgress
  },
  {
    path: '/new-crawl-ready',
    name: 'NewCrawlReady',
    component: NewCrawlReady
  },
  {
    path: '/join-crawl/:code?',
    name: 'JoinCrawl',
    component: JoinCrawl
  },
  {
    path: '/join-success',
    name: 'JoinSuccess',
    component: JoinSuccess
  },
  {
    path: '/crawl-clue',
    name: 'CrawlClue',
    component: CrawlClue
  },
  {
    path: '/crawl-check-in',
    name: 'CrawlCheckIn',
    component: CrawlCheckIn
  },
  {
    path: '/crawl-arrived',
    name: 'CrawlArrived',
    component: CrawlArrived,
  },
  {
    path: '/crawl-at-bar',
    name: 'CrawlAtBar',
    component: CrawlAtBar,
  },
  {
    path: '/crawl-trivia',
    name: 'CrawlTrivia',
    component: CrawlTrivia,
  },
  {
    path: '/crawl-trivia-answer',
    name: 'CrawlTriviaAnswer',
    component: CrawlTriviaAnswer,
  },
  {
    path: '/crawl-leaving',
    name: 'CrawlLeaving',
    component: CrawlLeaving,
  },
  {
    path: '/crawl-finished',
    name: 'CrawlFinished',
    component: CrawlFinished,
  },
  {
    path: '/crawl-options',
    name: 'CrawlOptions',
    component: CrawlOptions,
  },
  {
    path: '/crawl-invite',
    name: 'CrawlInvite',
    component: CrawlInvite,
  },
  {
    path: '/crawl-change-host',
    name: 'CrawlChangeHost',
    component: CrawlChangeHost,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
