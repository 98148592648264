<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Choose new host:</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-list>
        <ion-radio-group v-model="leaderUuid">
        <ion-list-header>
            <ion-label>Name</ion-label>
        </ion-list-header>
        <ion-item v-for="player in players" v-bind:key="player.unique_id">
            <ion-label>{{ player.name }}</ion-label>
            <ion-radio slot="start" :value="player.unique_id"></ion-radio>
        </ion-item>
        </ion-radio-group>
      </ion-list>
      <ion-button expand="full" @click="setHost()">Confirm</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  alertController,
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup
} from '@ionic/vue';
import { warning } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'CrawlInvite',
  components: {
    IonBackButton,
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRadio,
    IonRadioGroup
  },
  setup() {
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device;
    return {  warning,
              console,
              router: useRouter(),
              Storage}
  },
  data() {
      return {
          foo: 'bar',
          'crawl': {},
          apiUrl: "",
          players: [],
          leaderUuid: ""
      }
  },
  created: async function() {
    console.log('created');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    //apiMethods.crawlApi.Storage = this.Storage;
    //apiMethods.crawlApi.Device = this.Device;
    this.apiUrl = apiMethods.apiUrl;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.players = await apiMethods.crawlApi.listPlayers();
    //console.log(this.players);
    this.leaderUuid = this.crawl.leader_unique_id;
  },
  methods: {
      setHost: async function () {
          const self = this;
          console.log('trying to set host... ' + this.leaderUuid)
          if (!this.leaderUuid) {
              return false;
          }
          if (apiMethods.crawlApi.setHost(this.leaderUuid)) {
              self.presentAlert('The host of the crawl has been updated.');
              self.router.push('crawl-at-bar');
          } else {
              self.presentErrorAlert('Could not set host');
          }
      },
      presentAlert: async function(message) {
        const alert = await alertController
            .create({
            cssClass: 'my-custom-class',
            header: message,
            subHeader: '👍',
            message: 'You\'re good to go.',
            buttons: ['OK'],
            });
        return alert.present();
        },
        presentErrorAlert: async function(message) {
        const alert = await alertController
            .create({
            cssClass: 'my-custom-class',
            header: message,
            subHeader: '😕',
            message: 'Please try again.',
            buttons: ['OK'],
            });
        return alert.present();
        },
  }
});
</script>

<style scoped>

</style>