<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Options</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/home')">Main Menu</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/crawl-invite')">Invite friends</ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-if="isLeader">
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="() => router.push('/crawl-change-host')">Change host</ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-if="isLeader">
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="pauseCrawl()">Stay here longer</ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-if="isLeader">
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="resumeCrawl()">Leave this bar</ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-if="isLeader">
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="presentAlertConfirm('End the crawl?', endCrawl)">End the crawl</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  alertController,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'CrawlOptions',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device
    return {  warning,
              console,
              router: useRouter(),
              Storage }
  },
  data: function() {
    return {
      barName: "Bellhop",
      crawl: null,
      isLeader: false,
    }
  },
  created: async function() {
    console.log('Created.');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.isLeader = await apiMethods.crawlApi.isLeaderForCurrentGame();
  },
  computed: {
      minutesRemaining: function() {
          return 5;
      }
  },
  methods: {
    getDrinkSuggestion: async function(duration) {
      const self = this;
      const drinkData = await apiMethods.crawlApi.getDrinkSuggestion();
      self.drinkImage = drinkData.drinkImage;
      self.drink = drinkData.drink;
      self.drinkSuggested = true;
    },
    pauseCrawl: async function() {
      console.log('pausing crawl');
      const self = this;
      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      const deviceInfo = await apiMethods.crawlApi.Device.getInfo();
      const response = await fetch(apiMethods.crawlApi.url + 'api/games/pause/' + apiMethods.crawlApi.crawl.code, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "unique_id": uniqueId,
            "device_id": deviceInfo.uuid,
          }),
      });
      const crawlData = await response.json();
      if(false === crawlData.result) {
        console.log('pause unsuccessful');
        self.presentErrorAlert('Pause unsuccessful');
      } else {
        self.presentAlert('Crawl paused!');

        // To do: stop the minutes countdown (or hide it?)
      }
    },
    resumeCrawl: async function() {
      const self = this;
      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      const deviceInfo = await apiMethods.crawlApi.Device.getInfo();
      const response = await fetch(apiMethods.crawlApi.url + 'api/games/resume/' + apiMethods.crawlApi.crawl.code, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "unique_id": uniqueId,
            "device_id": deviceInfo.uuid,
          }),
      });
      const crawlData = await response.json();
      if(false === crawlData.result) {
        console.log('resume unsuccessful');
        self.presentErrorAlert('Could not resume');
      } else {
        self.presentAlert('Crawl resuming!');
      }
    },
    endCrawl: async function() {
      const self = this;
      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      const deviceInfo = await apiMethods.crawlApi.Device.getInfo();
      const response = await fetch(apiMethods.crawlApi.url + 'api/games/end/' + apiMethods.crawlApi.crawl.code, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "unique_id": uniqueId,
            "device_id": deviceInfo.uuid,
          }),
      });
      const crawlData = await response.json();
      if(false === crawlData.result) {
        console.log('end crawl unsuccessful');
        self.presentErrorAlert('Could not end the crawl');
      } else {
        self.presentAlert('Crawl finished!');
      }
    },
    getPlayersList: async function() {
      const self = this;
      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      const deviceInfo = await apiMethods.crawlApi.Device.getInfo();
      const response = await fetch(apiMethods.crawlApi.url + 'api/games/players/' + apiMethods.crawlApi.crawl.code, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "unique_id": uniqueId,
            "device_id": deviceInfo.uuid,
          }),
      });
      const crawlData = await response.json();
      if(false === crawlData.result) {
        console.log('players list unsuccessful');
        self.presentErrorAlert('Could not get players list');
      } else {
        console.log('got players list');
        console.log(crawlData);
      }
    },
    setHost: async function(uniquePlayerId) {
      const response = await apiMethods.crawlApi.setHost(uniquePlayerId);

      console.log(response);
    },
    presentAlert: async function(message) {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: message,
          subHeader: '👍',
          message: 'You\'re good to go.',
          buttons: ['OK'],
        });
      return alert.present();
    },
    presentErrorAlert: async function(message) {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: message,
          subHeader: '😕',
          message: 'Please try again.',
          buttons: ['OK'],
        });
      return alert.present();
    },
    presentAlertConfirm: async function(message, callback) {
      const self = this;
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: message,
          message: 'Are you sure?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'Yes',
              handler: () => {
                callback();
                console.log('Confirm Okay');
              },
            },
          ],
        });
      return alert.present();
    },
  }
});
</script>

<style scoped>

</style>