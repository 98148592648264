<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/newCrawlLocation"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="() => router.push('/crawl-options')">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Current Crawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Current Status:</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block"  size="large" color="dark" strong  @click="fetchCrawl()">Update</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>{{ crawl == null ? '' : crawl.code + ' ' + crawl.status + ' ' + ( isLeader ? 'L' : 'M' ) }}</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block" @click="() => router.push('/crawl-clue')">Crawl Clue</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" @click="() => router.push('/crawl-at-bar')">Crawl Bar</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning, ellipsisHorizontal } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js';

export default defineComponent({
  name: 'CrawlInProgress',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              ellipsisHorizontal,
              console,
              router: useRouter(),
              Storage }
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  data() {
      return {
          'crawl': {},
          'code': '2345',
          'isLeader': false,
      };
  },
  ionViewWillEnter: async function() {
    await apiMethods.crawlApi.init();
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.isLeader = await apiMethods.crawlApi.isLeaderForCurrentGame();
  },
  methods: {
    fetchCrawl: async function() {
      this.crawl = await apiMethods.crawlApi.fetchCrawl();
    }
  }
});
</script>

<style scoped>

</style>